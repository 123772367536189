import React from "react"

export const PG_MTR_ADVANTAGE_CONTENT = {
  points: [
    <div>
      <b>Your spend zero time on product research</b> with the AMZScout Monthly
      Report!
    </div>,
    <div>
      <b>You are certain that from now on, the amount of successful products</b>{" "}
      will be much higher. You are among the first to get into growing niches
      and take 90% of sales.
    </div>,
    <div>
      <b>Your business grows naturally</b> and margins get higher
    </div>,
  ],
}
