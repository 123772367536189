import React from "react";
import HOME_INTRO_SCREEN_CONTENT from "./index.content";

const HomeIntroScreen = () => {
  const { Png, Png_2x, PngMob, PngMob2x, Webp, Webp_2x, WebpMob, WebpMob2x } =
    HOME_INTRO_SCREEN_CONTENT;

  return (
    <div className="PgMTR-HomeIntro__img">
      <picture>
        <source
          type="image/webp"
          media="(min-width: 768px)"
          srcSet={`${Webp} 1x, ${Webp_2x} 2x`}
        />
        <source media="(min-width: 768px)" srcSet={`${Png} 1x, ${Png_2x} 2x`} />
        <source
          type="image/webp"
          media="(max-width: 767px)"
          srcSet={`${WebpMob} 1x, ${WebpMob2x} 2x`}
        />
        <source
          media="(max-width: 767px)"
          srcSet={`${PngMob} 1x, ${PngMob2x} 2x`}
        />
        <img src={Png} alt="" />
      </picture>
    </div>
  );
};

export default HomeIntroScreen;
