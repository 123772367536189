import Png from "../../assets/images/raster/pages/monthly-trend-report/happyCustomersSay/simon.png"
import Webp from "../../assets/images/raster/pages/monthly-trend-report/happyCustomersSay/simon.webp"

export const PG_MTR_HAPPY_CUSTOMERS_SAY_CONTENT = {
  infoText: [
    "I’m just starting my business and I was at a loss for ideas about what to sell on Amazon after my first item. There is just so much to consider when choosing a product, like profitability, competition, whether it’s a growing trend, and so on.",
    "AMZScout reports are by far one of the most helpful reports out there if you want to choose a product and sell on Amazon. The in-depth analytics you get for each niche is extraordinary. If it wasn't for AMZScout, I couldn't have chosen and launched my new product.",
  ],
  author: {
    name: "Simon",
    description: "a beginner Amazon seller",
    picPng: Png,
    picWebp: Webp,
  },
}
