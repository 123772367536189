import React from "react";
import PointIcon from "../../../assets/images/vector/components/common/group-copy.svg";
import { PG_MTR_SECTION_2_CONTENT } from "./index.content";
import "./index.scss";

const Section2 = () => {
  const {
    list1,
    list2,
    bigPic: { pic, pic2x, picMob, picMob2x, webp, webp2x, webpMob, webpMob2x },
  } = PG_MTR_SECTION_2_CONTENT;

  return (
    <section className="PgMTR-Section2">
      <div className="container">
        <h2 className="PgMTR-Section2__title">HOW we do it</h2>
        <div className="PgMTR-Section2-list">
          {list1.map(({ img, text, title }) => (
            <div key={title} className="PgMTR-Section2-list__item">
              <img src={img} alt="" loading="lazy" />
              <p>{title}</p>
              <p>{text}</p>
            </div>
          ))}
        </div>
        <div className="PgMTR-Section2__hr" />
        <div className="PgMTR-Section2-content">
          <div className="PgMTR-Section2-content__title">
            Products in the monthly report are
          </div>
          <div className="PgMTR-Section2-info">
            <div className="PgMTR-Section2-points">
              {list2.map((text, index) => (
                <div key={index} className="PgMTR-Section2-points__item">
                  <img src={PointIcon} alt="" loading="lazy" />
                  {text()}
                </div>
              ))}
              <div className="PgMTR-Section2__img">
                <picture>
                  <source
                    type="image/webp"
                    media="(min-width: 768px)"
                    srcSet={`${webp} 1x, ${webp2x} 2x`}
                  />
                  <source
                    media="(min-width: 768px)"
                    srcSet={`${pic} 1x, ${pic2x} 2x`}
                  />
                  <source
                    type="image/webp"
                    media="(max-width: 767px)"
                    srcSet={`${webpMob} 1x, ${webpMob2x} 2x`}
                  />
                  <source
                    media="(max-width: 767px)"
                    srcSet={`${picMob} 1x, ${picMob2x} 2x`}
                  />
                  <img src={pic} alt="" loading="lazy" />
                </picture>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Section2;
