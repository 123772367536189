import React, { useState } from "react";
import FastImage from "../../../containers/common/FastImage";
import { graphql, useStaticQuery } from "gatsby";

const HomeIntroVideo = () => {
  const [isShowVideo, setIsShowVideo] = useState(false);

  const data = useStaticQuery(graphql`
    query {
      allFile(
        filter: {
          name: { regex: "/mtr-homeIntro-video/" }
          ext: { eq: ".jpg" }
        }
      ) {
        edges {
          node {
            id
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                placeholder: BLURRED
                formats: [AUTO, WEBP]
                blurredOptions: { toFormat: AUTO }
                jpgOptions: { progressive: false }
              )
            }
            name
          }
        }
      }
    }
  `);

  const classes = [
    "PgMTR-HomeIntroVideo__inner",
    !isShowVideo && "PgMTR-HomeIntroVideo__play",
  ]
    .filter(Boolean)
    .join(" ");

  return (
    <div className="PgMTR-HomeIntroVideo">
      <div onClick={() => setIsShowVideo(true)} className={classes}>
        {isShowVideo ? (
          <iframe
            src="https://www.youtube.com/embed/HLoPY2p3uIo?autoplay=1&modestbranding=1"
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            title="youtube"
          />
        ) : (
          <FastImage
            imageData={data}
            imageClassName="PgMTR-HomeIntroVideo__img"
            imageAltName="home image"
            // isAdaptive
            isWithRatio
            defaultName="mtr-homeIntro-video"
          />
        )}
      </div>
    </div>
  );
};

export default HomeIntroVideo;
