import Png from "../../../../assets/images/raster/pages/monthly-trend-report/HomeIntro/HomeIntroScreen/mtr-homeIntro.png"
import Png_2x from "../../../../assets/images/raster/pages/monthly-trend-report/HomeIntro/HomeIntroScreen/mtr-homeIntro@2x.png"
import PngMob from "../../../../assets/images/raster/pages/monthly-trend-report/HomeIntro/HomeIntroScreen/mtr-homeIntro@mob.png"
import PngMob2x from "../../../../assets/images/raster/pages/monthly-trend-report/HomeIntro/HomeIntroScreen/mtr-homeIntro@mob2x.png"
import Webp from "../../../../assets/images/raster/pages/monthly-trend-report/HomeIntro/HomeIntroScreen/mtr-homeIntro.webp"
import Webp_2x from "../../../../assets/images/raster/pages/monthly-trend-report/HomeIntro/HomeIntroScreen/mtr-homeIntro@2x.webp"
import WebpMob from "../../../../assets/images/raster/pages/monthly-trend-report/HomeIntro/HomeIntroScreen/mtr-homeIntro@mob.webp"
import WebpMob2x from "../../../../assets/images/raster/pages/monthly-trend-report/HomeIntro/HomeIntroScreen/mtr-homeIntro@mob2x.webp"

const HOME_INTRO_SCREEN_CONTENT = {
  Png,
  Png_2x,
  PngMob,
  PngMob2x,
  Webp,
  Webp_2x,
  WebpMob,
  WebpMob2x,
}
export default HOME_INTRO_SCREEN_CONTENT
