import React from "react";
import "./index.scss";

const Possibility = () => {
  return (
    <section className="PgMTR-Possibility">
      <div className="container">
        <div className="PgMTR-Possibility-content">
          <h2 className="PgMTR-Possibility__title">
            Right now, you have a chance to receive a report of emerging
            products and trends on Amazon, as well as eCom news, insights, and
            tips on maximizing your business profit
          </h2>
          <p className="PgMTR-Possibility__text">
            When you oversee the opportunity, your competitors take up 90% of
            sales in new niches
          </p>
          <p className="PgMTR-Possibility__text">
            <b>Change that to the opposite</b>
          </p>
        </div>
      </div>
    </section>
  );
};
export default Possibility;
