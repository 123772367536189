import React from "react";
import PropTypes from "prop-types";
import LinkSampleReport from "../../common/LinkSampleReport";
import QuotesSVG from "../../../assets/images/vector/pages/report-deals/CustomersSay/quotes.svg";

import "./index.scss";
import { getTrueClasses } from "../../../helpers";

const CustomersSay = (props) => {
  const { customClass, data, isReportDealsPage, handlerClick } = props;
  const {
    infoText,
    author: { picPng, picWebp, name, description },
  } = data;
  const link =
    "https://amzscout.net/goto/?url=https://downloads.ctfassets.net/0yu6ph4occja/5E1b8Z9QxCkiF3mIItbVaL/136c2505e59658cf5f009f4c49b51045/SAMPLE_Report.pdf";

  return (
    <section
      className={getTrueClasses(
        "PgMTR-CustomersSay",
        isReportDealsPage && "PgMTR-CustomersSay__isReportDealsPage",
        customClass
      )}
    >
      <div className="PgMTR-CustomersSay__successStory" id="success-story" />
      <div className="container">
        <h2 className="PgMTR-CustomersSay__title">
          What our happy customers say
        </h2>
        <div className="PgMTR-CustomersSay-content">
          <div className="PgMTR-CustomersSay__text">
            <img src={QuotesSVG} alt="" loading="lazy" />
            {infoText.map((item, i) => (
              <p key={i}>{item}</p>
            ))}
          </div>
          <div className="PgMTR-CustomersSay-author">
            <div className="PgMTR-CustomersSay-author__img">
              <picture>
                <source type="image/webp" srcSet={picWebp} />
                <img src={picPng} alt="" loading="lazy" />
              </picture>
            </div>
            <div className="PgMTR-CustomersSay-author__name">
              <div>{name}</div>
              <div>{description}</div>
            </div>
          </div>
        </div>
      </div>
      {isReportDealsPage ? (
        <div className="PgMTR-CustomersSay__blockButton PgMTR-CustomersSay-blockButton">
          <div
            className="PgMTR-CustomersSay-blockButton__button PgMTR-CustomersSay__individualReport"
            onClick={handlerClick}
          >
            GET THE INDIVIDUAL REPORT
          </div>
          <a
            href={link}
            target="_blank"
            className="PgMTR-CustomersSay-blockButton__button PgMTR-CustomersSay__sampleReport"
          >
            CHECK OUT THE SAMPLE REPORT
          </a>
        </div>
      ) : (
        <>
          <a href="#sectionPricing" className="PgMTR-CustomersSay__button">
            Get the Individual Report
          </a>
          <LinkSampleReport />
        </>
      )}
    </section>
  );
};

export default CustomersSay;

CustomersSay.propTypes = {
  infoText: PropTypes.arrayOf(PropTypes.string),
  author: PropTypes.shape({
    picPng: PropTypes.string,
    picWebp: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
  }),
  customClass: PropTypes.string,
};
