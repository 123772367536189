import React from "react"
import SvgOne from "../../../assets/images/vector/pages/report-deals/HowWeDoIt/reportDeals-section2-1.svg"
import SvgTwo from "../../../assets/images/vector/pages/report-deals/HowWeDoIt/reportDeals-section2-2.svg"
import Png from "../../../assets/images/raster/pages/monthly-trend-report/Section2/mtr-section2.png"
import Png2x from "../../../assets/images/raster/pages/monthly-trend-report/Section2/mtr-section2@2x.png"
import PngMob from "../../../assets/images/raster/pages/monthly-trend-report/Section2/mtr-section2@mob.png"
import PngMob2x from "../../../assets/images/raster/pages/monthly-trend-report/Section2/mtr-section2@mob2x.png"
import Webp from "../../../assets/images/raster/pages/monthly-trend-report/Section2/mtr-section2.webp"
import Webp2x from "../../../assets/images/raster/pages/monthly-trend-report/Section2/mtr-section2@2x.webp"
import WebpMob from "../../../assets/images/raster/pages/monthly-trend-report/Section2/mtr-section2@mob.webp"
import WebpMob2x from "../../../assets/images/raster/pages/monthly-trend-report/Section2/mtr-section2@mob2x.webp"

export const PG_MTR_SECTION_2_CONTENT = {
  list1: [
    {
      img: SvgOne,
      title:
        "We use AI to analyze millions of niches by multiple criteria to make a pool of the strongest product candidates",
      text: "There are over 600 million products only on Amazon U.S., and we take full advantage of that variety.",
    },
    {
      img: SvgTwo,
      title: "AMZScout experts hand pick the best products and niches",
      text: "out of that pool and pack them into a monthly trend report",
    },
  ],
  list2: [
    () => (
      <>
        <b>Tailored to the priority categories</b> and range of products on
        Amazon
      </>
    ),
    () => (
      <>
        <b>Outperforming by parameters</b> like profit margin, revenue, growth
        pace, sales velocity, and low competition
      </>
    ),
    () => (
      <>
        <b>Selected by AMZScout experts with over 4 years of experience</b> in
        Amazon product research
      </>
    ),
  ],
  bigPic: {
    pic: Png,
    pic2x: Png2x,
    picMob: PngMob,
    picMob2x: PngMob2x,
    webp: Webp,
    webp2x: Webp2x,
    webpMob: WebpMob,
    webpMob2x: WebpMob2x,
  },
}
